import {
  Button,
  Grid,
  Switch,
  Tooltip,
  Typography,
  FormControl,
  List,
  ListItem,
  ListItemText,
  IconButton,
  TextField,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import MiniDrawer from "../../Components/Drawer";

import dayjs from 'dayjs';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';


import BaseSetting from "../../Apis/setting";
import { toast } from "react-toastify";
import EditIcon from "@mui/icons-material/Edit";
import { useNavigate } from "react-router-dom";
import { DataGrid } from "@mui/x-data-grid";
import DeleteIcon from "@mui/icons-material/Delete";
import { useDispatch, useSelector } from "react-redux";
import CModal from "../../Components/CModal";

import { isMobile } from "react-device-detect";
import CButton from "../../Components/CButton";
import BorderButton from "../../Components/BorderButton";
import { getApiData, getApiData1, getApiDatawebinar } from "../../Apis/apiHelper";

const moment = require("moment");


const DemoItem = ({ label, children }) => (
  <div>
    <h2>{label}</h2>
    <div>{children}</div>
  </div>
);


export default function Webinar({style }) {
  const defaultValue = dayjs('2022-04-17T15:30');
  const [rows, setRows] = useState([]);
  const [open, setOpen] = useState(false);
  const [deletemodal ,setDeleteModal] = useState(false);
  const [date, setDate] = useState('');
  const [time, setTime] = useState("");
  const [actualPrice, setActualPrice] = useState(null);
  const [offerPrice, setOfferPrice] = useState(null);
  const [paymentLink, setPaymentLink] = useState('');
  const [hover, setHover] = useState(false);
  const [platform, setPlatform] = useState('');
  const [whatsappLink, setWhatsappLink] = useState('');
  const [enrollCount, setEnrollCount] = useState(null);
  const [errors, setErrors] = useState({});
  const [slectedID ,SetSelctedID] = useState(null);
  const [update, setupdate] = useState(false)
  const[updateid, setUpdateID ] =useState(null)
  const [loading , setLoading] =useState(false)
  
  const accessToken = useSelector((state) => state.auth);
  console.log("accessToken==>", accessToken.accessToken);
  const navigate = useNavigate();


  const format12HourTime = (time24) => {
    const [hour24, minute] = time24.split(':');
    let hour = parseInt(hour24, 10);
    const suffix = hour >= 12 ? 'PM' : 'AM';
    hour = hour % 12 || 12;
    return `${hour}:${minute} ${suffix}`;
  };


 
  const convertTo24HourTime = (time12) => {
    const [time, modifier] = time12.split(' ');
    let [hours, minutes] = time.split(':');
    if (hours === '12') {
      hours = '00';
    }
    if (modifier === 'PM') {
      hours = parseInt(hours, 10) + 12;
    }
    return `${hours}:${minutes}`;
  }
  const handleAdd =  async () => {
 
    const newErrors = {};
    if (!date) newErrors.date = 'Date is required';
    if (!time) newErrors.time = 'Time is required';
    if (!actualPrice) newErrors.actualPrice = 'Actual Price is required';
    if (!offerPrice) newErrors.offerPrice = 'Offer Price is required';
    if (!paymentLink) newErrors.paymentLink = 'Payment Link is required';
    if (!platform) newErrors.platform = 'Platform is required';
    if (!whatsappLink) newErrors.whatsappLink = 'WhatsApp Link is required';
    if (!enrollCount) newErrors.enrollCount = 'Enroll Count is required';

    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors);
    } else {
      // Handle the add logic here
      console.log({ date, time, actualPrice, offerPrice, paymentLink, platform, enrollCount,whatsappLink });
      const formData = {
        date,
        time:time,
        actual_price: actualPrice,
        offer_price: offerPrice,
        payment_link: paymentLink,
        platform: platform,
        enrollcount: enrollCount,
        whatsapp_group_link: whatsappLink
      };
      setLoading(true);
      try {
        // const response = await fetch(`${process.env.REACT_APP_WEBINAR}/webinar/addWebinar`, {
        //   method: 'POST',
        //   headers: {
        //     'Content-Type': 'application/json',
        //   },
        //      body: JSON.stringify(formData),
        // });

        const endpoint = `${process.env.REACT_APP_WEBINAR}/webinar/addWebinar`;

        const response = await getApiData(
          endpoint,
          "POST",formData
        );

        console.log(response);

        if (!response.status == 200) {
          throw new Error('Network response was not ok');
        }

        // Handle the response
        const data = response;
        console.log(data);
        setLoading(false);
        Webinar();
        // Clear the form and errors after successful submission
        setDate('');
        setTime('');
        setActualPrice('');
        setOfferPrice('');
        setPaymentLink('');
        setPlatform('');
        setWhatsappLink('');
        setEnrollCount('');
        setErrors({});
        setupdate(false)
        setOpen(false);
      } catch (error) {
        console.error('There was a problem with the fetch operation:', error);
        // Optionally set an error state to show feedback to the user
      }
    }
  };
  const handleCancel = () =>{
    setDate('');
    setTime('');
    setActualPrice('');
    setOfferPrice('');
    setPaymentLink('');
    setPlatform('');
    setWhatsappLink('');
    setEnrollCount('');
    setErrors({});
    setOpen(false);
    setupdate(false)
  }
  const handleChange = (field, value) => {
    switch (field) {
      case 'date':
        setDate(value);
        break;
      case 'time':
        setTime(value);
        break;
      case 'actualPrice':
        setActualPrice(value);
        break;
      case 'offerPrice':
        setOfferPrice(value);
        break;
      case 'paymentLink':
        setPaymentLink(value);
        break;
      case 'platform':
        setPlatform(value);
        break;
        case 'whatsappLink':
        setWhatsappLink(value);
        break;
      case 'enrollCount':
        setEnrollCount(value);
        break;
      default:
        break;
    }
    setErrors((prevErrors) => ({ ...prevErrors, [field]: '' }));
  };

  function convertDateFormat(dateString) {
    // Parse the date string using Moment.js
    const date = moment(dateString);

    // Format the date into a normal date format
    const normalDateFormat = date.format("YYYY-MM-DD");

    return normalDateFormat;
  }

  const handleTimeChange = (newTime) => {

    console.log('newTime--->>', moment(newTime).format('hh:mm:A'));
    if (dayjs(newTime).isValid()) {
      setTime(moment(newTime).format('hh:mm:A'));
    } else {
      console.error('Invalid time format');
      // Handle error or set a default value
    }
  };
  const handleUpdate = async () => {
    // Prepare the updated data
 // Parse the timestamp using moment
//  const parsedTime = moment(time);

 console.log('time ---->>>' , time)

    // const formattedTime = parsedTime.format('hh:mm:A');
    const updatedWebinar = {
      date,
      time : time,
      actual_price :actualPrice,
      offer_price : offerPrice,
      payment_link : paymentLink,
      platform: platform,
      whatsapp_group_link: whatsappLink,
      enrollcount :enrollCount.toString(),
    };
  
    // Example API endpoint (replace with your actual endpoint)
    const apiUrl = `${process.env.REACT_APP_WEBINAR}/webinar/updateWebinar/${updateid}`;
  
    // Example PUT request using fetch
    try {
  
      const response = await fetch(apiUrl, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          // You might need additional headers like authorization token
        },
        body: JSON.stringify(updatedWebinar),
      });
  
      // Check if the request was successful
      if (!response.ok) {
        throw new Error('Failed to update webinar');
      }
  
      // Handle success
      console.log('Webinar updated successfully');
      Webinar(); // Example function to fetch updated webinar data
      handleCancel(); // Example function to close modal or reset form
      setupdate(false); // Reset update state if needed
    } catch (error) {
      // Handle errors
      console.error('Error updating webinar:', error.message);
      // Optionally, update state or show error message to user
    }
  };
  
  async function FinClubUsers() {
    try {
      const response = await getApiDatawebinar(
        BaseSetting.endpoint.webinar,
        "GET"
      );
      const data = response;
      const receivedRows = data.result?.map((item, index) => ({
        id: item._id,
        idindex : index+ 1,
        date: convertDateFormat(item.date),
        time: item.time,
        actualPrice: item.actual_price,
        offerPrice: item.offer_price,
        platForm: item.platform,
        payment_link: item.payment_link,
        enrollcount: item.enrollcount.toString(),
        groupLink: item.whatsapp_group_link,
        createdAt: convertDateFormat(item.created_at),
        updatedAt: convertDateFormat(item.updatedAt),
      }));
      // eslint-disable-next-line no-const-assign
      setRows(receivedRows);
    } catch (error) {
      console.log("ERROR=====>>>>>", error);
      toast(error.toString(), { type: "error" });
    }
  }
 const handleDelete = (id) => {
  // Perform your delete API call here
  console.log("DEleteId" , id)
  SetSelctedID(id);
  setDeleteModal(true);
 };

const confirmDelete = async () => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_WEBINAR}/webinar/deleteWebinar/${slectedID}`,
        {
          method: "DELETE",
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      if (response.ok) {
        console.log(`Deleted item with ID ${slectedID}`);
        setDeleteModal(false);
        SetSelctedID(null);
        Webinar();
      } else {
        console.error("Failed to delete item:", response.statusText);
      }
    } catch (error) {
      console.error("Error deleting item:", error);
    }
  };
  const handleEdit = (id) => {
  
    const webinarToEdit = rows.find((webinar) => webinar.id === id);
    if (webinarToEdit) {
      setUpdateID(id);
      setDate(convertDateFormat(webinarToEdit.date));
      setTime(webinarToEdit.time);
      setActualPrice(webinarToEdit.actualPrice);
      setOfferPrice(webinarToEdit.offerPrice);
      setPaymentLink(webinarToEdit.payment_link);
      setPlatform(webinarToEdit.platForm);
      setEnrollCount(webinarToEdit.enrollcount);
      setWhatsappLink(webinarToEdit.groupLink);
      setOpen(true);
      setupdate(true)
     
    } else {
      console.error(`Webinar with ID ${id} not found.`);
    }
  };

  async function Webinar() {
    try {
      const authToken = accessToken.accessToken;
      console.log("authToken1234===>", authToken);

      // Make the GET request with headers
      const response = await fetch(
        `${process.env.REACT_APP_WEBINAR}/webinar/webinarDetail`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            // Authorization: `Bearer ${authToken}`,
          },
        }
      );

      // Check if the response is unauthorized
      if (response.status === 401) {
        console.log("Unauthorized: Invalid token");
        return;
      }

      // Parse response JSON
      const data = await response.json();
      console.log("data====>>>>", data.result);
      const receivedRows = data.result?.map((item, index) => ({
        id: item._id,
        idindex : index+ 1,
        date: convertDateFormat(item.date),
        time: item.time,
        actualPrice: item.actual_price,
        offerPrice: item.offer_price,
        platForm: item.platform,
        payment_link: item.payment_link,
        enrollcount: item.enrollcount,
        groupLink: item.whatsapp_group_link,
        createdAt: convertDateFormat(item.created_at),
        updatedAt: convertDateFormat(item.updatedAt),
      }));
      // eslint-disable-next-line no-const-assign
      setRows(receivedRows);
    } catch (error) {
      console.log("ERROR=====>>>>>", error);
      toast(error.toString(), { type: "error" });
      // Handle errors
    }
  }

  const columns = [
    { field: "idindex", headerName: "ID", width: 15 },
    { field: "date", headerName: "Date", width: 150 },
    { field: "time", headerName: "Time", width: 100 },
    { field: "actualPrice", headerName: "Actual Price", width: 120 },
    { field: "offerPrice", headerName: "Offer Price", width: 120 },
    { field: "platForm", headerName: "PlatForm", width: 200 },
    { field: "payment_link", headerName: "Payment Link", width: 300 },
    { field: "enrollcount", headerName: "EndrollCount", width: 180 },
    { field: "groupLink", headerName: "Group Link", width: 300 },
    { field: "createdAt", headerName: "Created At", width: 150 },

    { field: "updatedAt", headerName: "Upadted At", width: 180 },

    {
      field: "Edit",
      headerName: "Edit",
      width: 100,
      renderCell: (params) => (
        <IconButton
        onClick={() => handleEdit(params.row.id)}
        >
          {" "}
          {/* handleEdit function should handle edit action */}
          <EditIcon />
        </IconButton>
      ),
    },
    {
      field: "delete",
      headerName: "Delete",
      width: 100,
      renderCell: (params) => (
        <Tooltip title="Delete">
          <IconButton
          onClick={() => handleDelete(params.row.id)}
          >
            <DeleteIcon />
          </IconButton>
        </Tooltip>
      ),
    },
  ];

  useEffect(() => {
    // Webinar();
    FinClubUsers()
  }, []);

  return (
    <Grid container>
      <MiniDrawer
        header="Webinar"
        children={
          <>
            {console.log("rows====>>> ", rows)}
            <div
              style={{
                display: "flex",
                justifyContent: "flex-end",
                margin: 10,
              }}
            >
              <div
                style={{
                  backgroundColor: "#4154f1",
                  width: "150px",
                  borderRadius: 10,
                  cursor:"pointer",
                }}
                onClick={() => setOpen(true)}
              >
                <p style={{ textAlign: "center", color: "#fff" }}>
                  Add Webinar
                </p>
              </div>
            </div>
            <Grid>
              <div style={{ height: 650, width: "100%", paddingTop: 20 }}>
                <DataGrid
                  rows={rows}
                  columns={columns}
                  initialState={{
                    pagination: {
                      paginationModel: { page: 0, pageSize: 10 },
                    },
                  }}
                  pageSizeOptions={[10, 15, 20]}
                  disableSelectionOnClick
                />
              </div>
            </Grid>
          </>
        }
      />
      <CModal
        visible={open}
        onClose={handleCancel}
     
        title={update ? "Update Webinar" :"Add Webinar"}
        children={
          <Grid
            style={{
              minWidth: isMobile ? "350px" : "600px",
              width: "30vw",
              position: "relative",
             
              scrollbarWidth:0
            }}
          >
            <Grid container  justifyContent={"space-between"}>
            
            <Grid item xs={5} sx={{ margin: 3,paddingTop:"7px" }}>
            <TextField
              label="Date"
              type="date"
              fullWidth
              InputLabelProps={{
                shrink: true,
              }}
              value={date}
              onChange={(e) => handleChange('date', e.target.value)}
              error={!!errors.date}
              helperText={errors.date}
              
            />
            
          </Grid>
          
          <Grid item xs={5} sx={{ margin: 3,paddingTop:"7px" }}>
          {/* <LocalizationProvider dateAdapter={AdapterDayjs}>
          <DemoContainer components={['TimePicker', 'TimePicker']}> */}

          <TextField
              label="Time"
              type="text"
              fullWidth
              InputLabelProps={{
                shrink: true,
              }}
              value={time}
              onChange={(e) => handleChange('time', e.target.value)}
              error={!!errors.date}
              helperText={errors.date}
              placeholder="HH:MM:AM/PM"
              
            />
      
          {/* <TimePicker
  label="Time"
  // value={time}
  // ampm={true} // Ensure ampm prop is set to true for 12-hour format
  fullWidth
  sx={{ width: '100%' }}
  onChange={handleTimeChange}
  renderInput={(params) => <TextField {...params} fullWidth />}
/> */}
      {/* </DemoContainer>
             </LocalizationProvider> */}
             </Grid>
          <Grid item xs={12} sx={{ margin: 3 }}>
            <TextField
              label="Actual Price"
              type="number"
              fullWidth
              InputLabelProps={{
                shrink: true,
              }}
              value={actualPrice}
              onChange={(e) => handleChange('actualPrice', e.target.value)}
              error={!!errors.actualPrice}
              helperText={errors.actualPrice}
            />
          </Grid>
          <Grid item xs={12} sx={{ margin: 3 }}>
            <TextField
              label="Offer Price"
              type="number"
              fullWidth
              InputLabelProps={{
                shrink: true,
              }}
              value={offerPrice}
              onChange={(e) => handleChange('offerPrice', e.target.value)}
              error={!!errors.offerPrice}
              helperText={errors.offerPrice}
            />
          </Grid>
          <Grid item xs={12} sx={{ margin: 3 }}>
            <TextField
              label="Payment Link"
              type="url"
              fullWidth
              InputLabelProps={{
                shrink: true,
              }}
              value={paymentLink}
              onChange={(e) => handleChange('paymentLink', e.target.value)}
              error={!!errors.paymentLink}
              helperText={errors.paymentLink}
            />
          </Grid>
          <Grid item xs={12} sx={{ margin: 3 }}>
            <TextField
              label="Platform"
              type="text"
              fullWidth
              InputLabelProps={{
                shrink: true,
              }}
              value={platform}
              onChange={(e) => handleChange('platform', e.target.value)}
              error={!!errors.platform}
              helperText={errors.platform}
            />
          </Grid>
          <Grid item xs={12} sx={{ margin: 3 }}>
            <TextField
              label="WhatsApp Link"
              type="url"
              fullWidth
              InputLabelProps={{
                shrink: true,
              }}
              value={whatsappLink}
              onChange={(e) => handleChange('whatsappLink', e.target.value)}
              error={!!errors.whatsappLink}
              helperText={errors.whatsappLink}
            />
          </Grid>
          <Grid item xs={12} sx={{ margin: 3 }}>
            <TextField
              label="Enroll Count"
              type="number"
              fullWidth
              InputLabelProps={{
                shrink: true,
              }}
              value={enrollCount}
              onChange={(e) => handleChange('enrollCount', e.target.value)}
              error={!!errors.enrollCount}
              helperText={errors.enrollCount}
            />
          </Grid>
          <Grid item xs={12} container justifyContent="flex-end" style={{ marginTop: '20px'  ,marginBottom:10}}>
            <div style={{display:"flex" ,justifyContent:'space-between' ,alignItems:'center'}}>
          
            <BorderButton
            //  style={defaultStyle}
              onClick={handleCancel}
              onMouseEnter={() => setHover(true)}
         onMouseLeave={() => setHover(false)}
              loading={loading}
              variant="contained"
              sx={{FontWeight:'500'}}
            >
            CANCEL
              </BorderButton>
              <CButton
              style={{marginRight:"10px" }}
              onClick={update ? handleUpdate : handleAdd}
              loading={loading}
             
            >
               {update ? "UPDATE" :"ADD" }
            </CButton>
            </div>
          </Grid>
            </Grid>
            
          </Grid>
        }
      />
      <CModal
        visible={deletemodal}
        onClose={() =>setDeleteModal(false)}
        title={"Delete Webinar"}
        children={
          <Grid
            style={{
              minWidth: isMobile ? "350px" : "600px",
              width: "30vw",
              position: "relative",
             
              scrollbarWidth:0
            }}
          >
            <Grid container justifyContent={"center"} >
            
           
         
         
          
          
          <Grid item xs={12} sx={{ margin: 3 }}>
           <p style={{textAlign:'center' ,fontSize:20}}>
            Are You sure to delete this webinar ??
           </p>
          </Grid>
          <Grid item xs={12} container justifyContent="flex-end" style={{ marginTop: '0px'  ,marginBottom:10}}>
            <div style={{display:"flex" ,justifyContent:'space-between' ,alignItems:'center'}}>
              <BorderButton  onClick={() =>setDeleteModal(false)} sx={{marginBottom:"20px" ,paddingRight:10, paddingLeft:10}} width={'60px'}> NO </BorderButton>
            
            <CButton onClick={confirmDelete} sx={{marginRight:2}}>
              YES
            </CButton>
           
            </div>
          </Grid>
            </Grid>
            
          </Grid>
        }
      />
    </Grid>
  );
}
