/* eslint-disable jsx-a11y/alt-text */
import { React,  useState } from "react";
import { Grid } from "@mui/material";
import styles from "./styles";
import CInput from "../../Components/CInput";
import CButton from "../../Components/CButton";
import { FontFamily } from "../../Config/theme";
import { AiOutlineEye, AiOutlineEyeInvisible } from "react-icons/ai";
import CIcon from "../../Components/CIcon";
import Files from "../../Config/Files";
import BaseSetting from "../../Apis/setting";
import { getApiData } from "../../Apis/apiHelper";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import authActions from "../../Redux/Reducers/Auth/actions";
import BaseColor from "../../Config/Color";

const Login = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { setAccessToken, } = authActions;
  

  const classes = styles();
  const [showPassword, setShowPassword] = useState(false);
  const [forgot,] = useState(false);
 const [loading, setLoading] = useState(false);
  //state for form
  const [emailOrMobile, setEmailOrMobile] = useState("");
  const [password, setPassword] = useState("");
  const [otp, setOtp] = useState("");
  const [email, setEmail] = useState("");
  const [chnagetext , setCahngeText] = useState(false)
  //loader
  const [loginBtnLoading,setLoginBtnLoading] = useState(false);

  //Error messages
  const [emailOrMobileErrorText, setEmailOrMobileErrorText] = useState("");
  const [passwordErrorText, setPasswordErrorText] = useState("");
  const [emailErrorText, setEmailErrorText] = useState("");

  // useEffect(() => {
  //   const listener = (event) => {
  //     if (event.code === "Enter" || event.code === "NumpadEnter") {
  //       validation();
  //       // event.preventDefault();
  //     }
  //   };
  //   document.addEventListener("keydown", listener);
  //   return () => {
  //     document.removeEventListener("keydown", listener);
  //   };
  // }, []);

 

  const validation = () => {
    if (emailOrMobile.trim() === "") {
      window.scrollTo(0, 0);
      allErrorFalse();
      setEmailOrMobileErrorText("Email or mobile is required");
    }  else if (emailOrMobile.match(/^[6-9][0-9]{9}$/)) {
      window.scrollTo(0, 0);
      allErrorFalse();
      setEmailOrMobileErrorText("Enter valid mobile number");
    } else {
      
      // allErrorFalse();
      // LoginApiCall();
      // window.location.replace("/dashboard");
    }
  };

  console.log('process.env.REACT_APP_LOGIN_URL',process.env.REACT_APP_LOGIN_URL);

  async function LoginApiCall() {
    setLoading(true)
    try {
      const response = await getApiData(
       `${process.env.REACT_APP_LOGIN_URL}/api/admin-auth/request-otp`,
        "POST",
        { mobile: emailOrMobile }
      );
     console.log("response?.statu===>>", response?.status  , response);
      if (response?.status == 400 ) {
        toast(response?.message, { type: "error" });
        setLoading(false);
        // navigate("/all-pos;ts");
      
      } else if (response?.status == 200) {
        setCahngeText(true)
        setLoading(false);
      } else {

        toast(response?.message, { type: "error" });
        setLoading(false);
      }
    } catch (error) {
      console.log("ERROR=====>>>>>", error);
      toast(error.toString(), { type: "error" });
      setLoading(false);
    }
  }

  async function ResentOTPCall() {
    setLoading(true);
    try {
      const response = await getApiData(
       `${process.env.REACT_APP_LOGIN_URL}/api/admin-auth/resend-otp`,
        "POST",
        { mobile: emailOrMobile }
      );
     console.log("response?.statu===>>", response?.status  , response);
      if (response?.status) {
        toast(response?.message, { type: "success" });
      setLoading(false);
        // navigate("/all-pos;ts");
       
      } else {
        toast(response?.message, { type: "error" });
        setLoading(false);
      }
    } catch (error) {
      console.log("ERROR=====>>>>>", error);
      toast(error.toString(), { type: "error" });
      setLoading(false);
    }
  }
  async function verifyOTPCall() {
    
    try {
      const response = await getApiData(
       `${process.env.REACT_APP_LOGIN_URL}/api/admin-auth/verify-otp`,
        "POST",
        { mobile: emailOrMobile  , otp: otp}
      );
     console.log("response?.statu===>>", response?.status  , response);
      if (response?.status == 400) {
        toast(response?.message, { type: "error" });
        // navigate("/all-pos;ts");
      } else if (response?.status == 200) {
        console.log('response?.data?.token ====>', response?.token)
        dispatch(setAccessToken(response?.token));
        navigate("/webinar");
      } else {
        toast(response?.message, { type: "error" });
      }
    } catch (error) {
      console.log("ERROR=====>>>>>", error);
      toast(error.toString(), { type: "error" });
    }
  }
  const ForgotValidation = () => {
    if (email.trim() === "") {
      window.scrollTo(0, 0);
      allErrorFalse();
      setEmailErrorText("Email is required");
    } else if (email.charAt(0).match(/^[A-Za-z]+$/)) {
      if (email.match(/^\w+@[a-zA-Z_]+?\.[a-zA-Z]{2,3}$/))
        window.scrollTo(0, 0);
      allErrorFalse();
      setEmailErrorText("Enter valid email address");
    } else {
      setEmailErrorText("");
    }
  };

  const submitForm = (event) => {
    if (event.keyCode === 13) {
      validation();
    }
  };

  const ForgotPasswordFunction = () => {
    return (
      <div style={{ padding: "20px" }}>
        <Grid container justifyContent={"center"} alignItems={"center"}>
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12} textAlign={"left"}>
            <span style={{ fontFamily: FontFamily.Medium, fontSize: "16px" }}>
              Forgot Password?
            </span>
            <br />
            <span style={{ fontFamily: FontFamily.Medium, fontSize: "14px" }}>
              Enter the email address associated with your account
            </span>
          </Grid>

          <Grid
            item
            xs={12}
            sm={12}
            md={12}
            lg={12}
            xl={12}
            marginTop={"20px"}
            marginBottom={"20px"}
          >
            <CInput
              placeholder="Email"
              value={email}
              onChange={(val) => setEmail(val)}
              startIcon={
                <CIcon src={Files.svgIcons.email} className="CInputStartIcon" />
              }
              errorMsg={emailErrorText}
            />
          </Grid>
          {/* <Grid item xs={12}>
                <CInput
                  placeholder="Password"
                  type={showPassword ? "text" : "password"}
                  value={password}
                  onChange={(val) => setPassword(val)}
                  startIcon={<MdLockOutline className="CInputStartIcon" />}
                  errorMsg={passwordErrorText}
                  endIcon={
                    !showPassword ? (
                      <AiOutlineEye
                        className="CInputStartIcon"
                        onClick={() => setShowPassword(true)}
                      />
                    ) : (
                      <AiOutlineEyeInvisible
                        className="CInputStartIcon"
                        onClick={() => setShowPassword(false)}
                      />
                    )
                  }
                />
              </Grid> */}

          <Grid item xs={12}>
            <CButton
              style={{ marginTop: "10px" }}
              onClick={() => {
                ForgotValidation();
              }}
              // disabled={loading}
               loading={loading}
              variant="contained"
            >
              Continue
            </CButton>
          </Grid>
        </Grid>
      </div>
    );
  };
  const LoginFunction = () => {
    return (
      <div style={{ padding: "20px" }}>
        <Grid container  >
          <Grid
            item
            xs={12}
            sm={12}
            md={12}
            lg={12}
            xl={12}
           
          >
            <img
            alt=""
              src={Files?.images?.logo}
              style={{ height: 100, width: 300, objectFit: "contain" }}
            />
            <br />
            <br /><br/> 
            <span style={{ fontFamily: FontFamily.Bold, fontSize: "30px"  ,color: BaseColor.primary}}>
           {chnagetext ? "Verify OTP " : " Login "}   
            </span>
          </Grid>
          <Grid
            item
            xs={12}
            sm={12}
            md={12}
            lg={12}
            xl={12}
            marginTop={"20px"}
            marginBottom={"20px"}
          >

            {chnagetext ?   <CInput
              placeholder="otp"
              
              value={otp}
              onChange={(val) => {
                setOtp(val);
                
              }}  onKeyDown={(event) => {
                if (event.key === 'Enter') {
                  // Perform the desired action here
                  if (chnagetext) {
                    console.log('hello 123');
                     verifyOTPCall();
                  } else{
                    LoginApiCall();
                  }
                }
              }}
              
              errorMsg={emailOrMobileErrorText}
            /> :    
            <CInput
            placeholder="Registered Mobile"
            
            value={emailOrMobile}
            onChange={(val) => {
              setEmailOrMobile(val);
              allErrorFalse();
            }}
            onKeyDown={(event) => {
              if (event.key === 'Enter') {
                // Perform the desired action here
                if (chnagetext) {
                  console.log('hello 123');
                   verifyOTPCall();
                } else{
                  LoginApiCall();
                }
              }
            }}
            errorMsg={emailOrMobileErrorText}
          /> }
         
          </Grid>
       
       {chnagetext ? <>
        <Grid item xs={12}>
           <p>
           DIDN'T RECIEVE THE CODE?  <span style={{color:'#000' , fontWeight:'800'}}
           onClick={() => {ResentOTPCall()}}
           >
              RESENT OTP
           </span>
           </p>
          </Grid>
       </>   : null}   
       
          <Grid item xs={12}>
            <CButton
              style={{ marginTop: "30px", }}
              onClick={() => {
                console.log('hello 123');
                if (chnagetext) {
                  console.log('hello 123');
                   verifyOTPCall();
                } else{
                  LoginApiCall();
                }
             
              }}
              loading={loading}
              variant="contained"   
            >
             {chnagetext ? "Verify OTP" : "Login"}
            </CButton>
          </Grid>
        </Grid>
      </div>
    );
  };
  const allErrorFalse = () => {
    setEmailOrMobileErrorText("");
    setPasswordErrorText("");
  };

  return (
    <>
    <Grid container sx={{}}>
      <Grid
        item
        xs={12}
        sm={12}
        md={5}
        lg={5}
        xl={5}
        className={classes.mainContainer}
      >

      {/* <div className={classes.mainBoxContainer}> */}
       {LoginFunction()}
      {/* </div> */}
      </Grid>

      <Grid  item
      xs={0}
      sm={0}
      md={7}
      lg={7}
      xl={7}

      >
  <img
              src={Files?.images?.loginImg}
              style={{height: '100%', width: '100%',objectFit: 'cover'}}
          
            />
      </Grid>

    </Grid>
    </>
  );
};
export default Login;
